<template>
  <div>
    <v-row v-if="event">
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" sm="4"><b>Name: </b></v-col>
          <v-col cols="12" sm="8" v-if="event">{{ event.name }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4"><b>Date: </b></v-col>
          <v-col cols="12" sm="8" v-if="event">{{ event.date }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4"><b>Time: </b></v-col>
          <v-col cols="12" sm="8" v-if="event">{{ event.time }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4"><b>Location: </b></v-col>
          <v-col cols="12" sm="8">{{ event.location }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4"><b>Price: </b></v-col>
          <v-col cols="12" sm="8">$ &nbsp; {{ event.price }}</v-col>
        </v-row>
        <v-row v-if="event.available_for_volunteer && permissions.includes('events_attend_volunteer')">
          <v-col cols="12" sm="4"><b>Volunteer Roles: </b></v-col>
          <v-col cols="12" sm="8">{{ event.volunteer_roles }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <div>
          <google-map />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <hr>
        <h2>Time Slots</h2>
        <hr>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        From
      </v-col>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        To
      </v-col>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        Volunteers
      </v-col>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        Attendees
      </v-col>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        No Of Volunteers Needed
      </v-col>
      <v-col cols="12" sm="2" class="font-weight-bolder">
        Action
      </v-col>
    </v-row>
    <v-row v-for="timeslot in event.time_slots" v-bind:key="timeslot">
      <v-col cols="12" sm="2">
        {{ timeslot.from_time }}
      </v-col>
      <v-col cols="12" sm="2">
        {{ timeslot.to_time }}
      </v-col>
      <v-col cols="12" sm="2">
        {{ timeslot.volunteer_count }}
      </v-col>
      <v-col cols="12" sm="2">
        {{ timeslot.attendee_count }}
      </v-col>
      <v-col cols="12" sm="2">
        {{ timeslot.no_of_volunteers_needed }}
      </v-col>
      <v-col cols="12" sm="2">
        <v-tooltip top v-if="timeslot.volunteer_count > 0">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              small
              class="mx-1"
              v-on="on"
              @click="eventVoulenteerView(event.id,timeslot.id)"
            >
              <v-icon color="#B5B5C3">mdi-human</v-icon>
            </v-btn>
          </template>
          <span>View Voulenteer List</span>
        </v-tooltip>
        <v-tooltip top v-if="timeslot.attendee_count > 0">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              small
              class="mx-1"
              v-on="on"
              @click="eventAttendeeView(event.id,timeslot.id)"
            >
              <v-icon color="#B5B5C3">
                mdi-google-street-view
              </v-icon>
            </v-btn>
          </template>
          <span>View Attendee List</span>
        </v-tooltip>
      </v-col>
      
    </v-row>
    <!-- Dialogue start-->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ attendee_volunteer_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-data-table :headers="model_headers" :items="model_list">
                  <template v-slot:item="row">
                    <tr>
                      <td>{{ row.item.full_name }}</td>
                      <td>{{ row.item.email }}</td>
                      <td>{{ row.item.phone }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Dialog end -->
  </div>
</template>

<script>
import Vuetify from "vuetify";
import axios from "axios";

export default {
  vuetify: new Vuetify(),
  name: "ShowFields",
  props: {
    event: Object
  },
  data() {
    return {
      permissions: [],
      dialog: false,
      attendee_volunteer_title: "",
      model_headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Email", value: "email", width: "25%" },
        { text: "Contact Number", value: "contact", width: "25%" }
      ],
      model_list: [],
    }
  },
  watch: {
    getPermissions: function(newVal) {
      this.permissions = newVal;
    }
  },
  methods: {
    eventVoulenteerView(event_id, time_slot_id) {
      let context = this;
      axios({
        method: "post",
        url: "event/list-volunteers",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: { event_id: event_id, time_slot_id: time_slot_id }
      }).then(
        function(result) {
          if (result.data.result.user_details) {
            context.model_list = result.data.result.user_details;
          } else {
            context.model_list = [];
          }
          context.attendee_volunteer_title = "Volunteer List";
          context.dialog = true;
        },
        function() {
          Swal.fire("Error", "Error in fetching Event volunteer list", "info");
        }
      );
    },
    eventAttendeeView(event_id, time_slot_id) {
      let context = this;
      axios({
        method: "post",
        url: "event/list-attendees",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: { event_id: event_id, time_slot_id: time_slot_id }
      }).then(
        function(result) {
          if (result.data.result.user_details) {
            context.model_list = result.data.result.user_details;
          } else {
            context.model_list = [];
          }
          context.attendee_volunteer_title = "Attendees List";
          context.dialog = true;
        },
        function() {
          Swal.fire("Error", "Error in fetching Event attendees list", "info");
        }
      );
    }
  },
};
</script>
