<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Event Details</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToEventsList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_event_form"
          v-if="permissions.includes('events_attend_volunteer')"
        >
          <!--begin::Example-->
          <div class="example mb-10" v-if="user_permissions.includes('chapter_sign_up_attend_event')">
            <v-form ref="form">
              <show-fields v-if="load_component" :event="event" />
              <v-row fluid class="card-footer pl-3 pt-3">
                <v-btn
                  v-if="event.available_for_attend"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="attendEvent"
                >
                  Attend
                </v-btn>
                <span
                  v-if="
                    event.available_for_attend &&
                      event.available_for_volunteer
                    "
                  class="mt-2 font-weight-bold">
                  or
                </span>
                <v-btn v-if="event.available_for_volunteer"
                  color="success"
                  class="ml-4"
                  ref="kt_store_submit"
                  @click="volunteerEvent"
                >
                  Volunteer
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
        <form v-else>
          <h2> Glade to invite you to an Event. </h2>
        </form>
      </div>
    </div>
    <!--end::Events-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import { AVAILABLE_FOR_EVENT } from "@/core/services/store/event.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import ShowFields from "./ShowFields.vue";

export default {
  name: "EventShow",
  vuetify: new Vuetify(),
  components: {
    ShowFields
  },
  watch: {
    '$route.params': {
        handler(newValue) {
          this.getEvent(newValue.id);
        }
    },
    getPermissions: function(newVal) {
      this.permissions = newVal;
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["getUserPermissions"])
  },
  data() {
    return {
      permissions: [],
      user_permissions: [],
      event: {
        name: "",
        date_time: format(
          parseISO(new Date().toISOString()),
          "yyyy-MM-dd hh:mm"
        ),
        location: "",
        price: "",
        available_for_volunteer: 0,
        available_for_attend: 0,
        no_of_volunteers_needed: "",
        volunteer_roles: ""
      },
      load_component: false,
      event_id: this.$route.params.id
    };
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.getEvent();
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Event" }]);
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getEvent() {
      let context = this;
      axios({
        method: "get",
        url: "events/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.events) {
            context.event = result.data.events;
            context.load_component = true;
          } else {
            // context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "Event load error!", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Event not found!", "error");
        }
      );
    },
    attendEvent() {
      var req_obj = {
        available_for_type: "attendees",
        event_id: this.$route.params.id
      };

      let context = this;
      this.$store.dispatch(AVAILABLE_FOR_EVENT, req_obj).then(() => {
        context.disable_btn = false;
        this.$router.go(-1);
      });
    },
    volunteerEvent() {
      var req_obj = {
        available_for_type: "volunteer",
        event_id: this.$route.params.id
      };

      let context = this;
      this.$store.dispatch(AVAILABLE_FOR_EVENT, req_obj).then(() => {
        context.disable_btn = false;
        this.$router.go(-1);
      });
    },
    redirectToEventsList() {
      let context = this;
      context.$router.go(-1);
    }
  }
};
</script>
